import Cookies from 'js-cookie';
import { lazy } from 'react';

const TOKEN_STORAGE_KEY = 'token';
const TOKEN_REFRESH_KEY = 'urtfidqxsa';
const domain = process.env.COOKIE_DOMAIN;
const isProd = process.env.NODE_ENV === 'production';

// expire in 30 days, but on SSR it should live only 15 minutes
export const JWT_EXP = 30;

export const getAuthToken = () => Cookies.get(TOKEN_STORAGE_KEY);

export const setAuthToken = (token, persist = true) =>
  Cookies.set(TOKEN_STORAGE_KEY, token, {
    expires: JWT_EXP,
    secure: isProd,
    sameSite: 'strict',
    domain
  });

export const removeAuthToken = () => {
  Cookies.remove(TOKEN_STORAGE_KEY, { domain });
};

export const getRefreshToken = () => Cookies.get(TOKEN_REFRESH_KEY);

export const setRefreshToken = token =>
  Cookies.set(TOKEN_REFRESH_KEY, token, {
    expires: JWT_EXP,
    secure: isProd,
    sameSite: 'strict',
    domain
  });

export const removeRefreshToken = () => {
  Cookies.remove(TOKEN_STORAGE_KEY, { domain });
};

export const checkUserPermissions = (requiredPermissions, userPermissions) => {
  return requiredPermissions.every(val => userPermissions.includes(val));
};

export const validateRoutePermissions = (routes, userPermissions) => {
  // transform permission obj to array of codes
  // eslint-disable-next-line no-underscore-dangle
  const _userPermissions = Array.from(userPermissions, p => p.code);
  routes.map(routeDetails => {
    if (routeDetails.routes) {
      routeDetails.routes.map(route => {
        if (
          route.permissions &&
          !checkUserPermissions(route.permissions, _userPermissions)
        ) {
          // eslint-disable-next-line no-param-reassign
          route.component = lazy(() => import('src/views/Error403'));
        }
        return route;
      });
    }
    return routeDetails;
  });
  return routes;
};
