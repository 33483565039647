import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Typography, Button, useTheme, useMediaQuery } from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    paddingTop: '10vh',
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center'
  },
  imageContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  },
  image: {
    maxWidth: '100%',
    width: 560,
    maxHeight: 300,
    height: 'auto'
  },
  buttonContainer: {
    marginTop: theme.spacing(6),
    display: 'flex',
    justifyContent: 'center'
  }
}));

function Error401({ hasToken }) {
  const classes = useStyles();
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'));
  useEffect(() => {
    if (!hasToken) {
      window.location.replace(process.env.REACT_APP_FRONTEND_URL + '?signin=1');
    }
  }, [hasToken]);

  return (
    <Page className={classes.root} title="Error 401">
      <Typography align="center" variant={mobileDevice ? 'h4' : 'h1'}>
        401: We are sorry but we are not able to authenticate you.
      </Typography>
      <Typography align="center" variant="subtitle2">
        You either tried some shady route or you came here by mistake. Whichever
        it is, try using the navigation
      </Typography>
      <div className={classes.imageContainer}>
        <img
          alt="Under development"
          className={classes.image}
          src="/images/undraw_authentication_fsn5.svg"
        />
      </div>
      <div className={classes.buttonContainer}>
        <Button
          color="primary"
          onClick={() =>
            window.location.replace(process.env.REACT_APP_FRONTEND_URL)
          }
          variant="outlined"
        >
          Back to home
        </Button>
      </div>
    </Page>
  );
}

export default Error401;
