import { useEffect } from 'react';
import { useLocation } from 'react-router';
import useSettings from '../hooks/useSettings';

function ScrollReset() {
  const location = useLocation();
  const { contentRef } = useSettings();

  useEffect(() => {
    if (contentRef) contentRef.scrollTo(0, 0);
  }, [location.pathname, location.search, contentRef]);

  return null;
}

export default ScrollReset;
