/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from './layouts/Dashboard';
import DashboardAnalyticsView from './views/DashboardAnalytics';
import DashboardDefaultView from './views/DashboardDefault';
import OverviewView from './views/Overview';
import ErrorLayout from './layouts/Error';
import { PermissionsEnum } from './core/enums';

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/management/orders/gold" />
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      {
        path: '/dashboards/analytics',
        exact: true,
        component: DashboardAnalyticsView
      },
      {
        path: '/dashboards/default',
        exact: true,
        component: DashboardDefaultView
      },
      {
        path: '/management/staff',
        exact: true,
        component: lazy(() => import('src/views/StaffManagementList')),
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        path: '/management/staff/:id',
        exact: true,
        component: lazy(() => import('src/views/StaffManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        path: '/management/staff/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/StaffManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        path: '/management/customers',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementList')),
        permissions: [PermissionsEnum.MANAGE_USERS]
      },
      {
        path: '/management/customers/:id',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_USERS]
      },
      {
        path: '/management/customers/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/CustomerManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_USERS]
      },
      {
        path: '/management/pages',
        exact: true,
        component: lazy(() => import('src/views/PageManagementList')),
        permissions: [PermissionsEnum.MANAGE_PAGES]
      },
      {
        path: '/management/pages/create',
        exact: true,
        component: lazy(() => import('src/views/PageDetails')),
        permissions: [PermissionsEnum.MANAGE_PAGES]
      },
      {
        path: '/management/pages/:id',
        exact: true,
        component: lazy(() => import('src/views/PageDetails')),
        permissions: [PermissionsEnum.MANAGE_PAGES]
      },
      {
        path: '/management/blog',
        exact: true,
        component: lazy(() => import('src/views/BlogManagementList')),
        permissions: [PermissionsEnum.MANAGE_BLOG]
      },
      {
        path: '/management/blog/create',
        exact: true,
        component: lazy(() => import('src/views/BlogDetails')),
        permissions: [PermissionsEnum.MANAGE_BLOG]
      },
      {
        path: '/management/blog/:id',
        exact: true,
        component: lazy(() => import('src/views/BlogDetails')),
        permissions: [PermissionsEnum.MANAGE_BLOG]
      },
      {
        path: '/management/groups',
        exact: true,
        component: lazy(() => import('src/views/GroupManagementList')),
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        path: '/management/groups/create',
        exact: true,
        component: lazy(() => import('src/views/GroupManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        path: '/management/groups/:id',
        exact: true,
        component: lazy(() => import('src/views/GroupManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        path: '/management/reviews',
        exact: true,
        component: lazy(() => import('src/views/FeedbackManagementList'))
      },
      {
        path: '/management/reviews/create',
        exact: true,
        component: lazy(() => import('src/views/FeedbackDetails'))
      },
      {
        path: '/management/reviews/:id',
        exact: true,
        component: lazy(() => import('src/views/FeedbackDetails'))
      },
      {
        path: '/management/orders/analytics',
        exact: true,
        component: lazy(() => import('src/views/OrderAnalytics'))
      },
      {
        path: '/management/orders/:type',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementList'))
        // TODO: dynamic permissions inside component ???
      },
      {
        path: '/management/orders/:type/:id',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementDetails'))
      },
      {
        path: '/management/orders/:type/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/OrderManagementDetails'))
      },
      {
        path: '/payouts',
        exact: true,
        component: lazy(() => import('src/views/PayoutList')),
        permissions: [PermissionsEnum.MANAGE_PAYOUTS]
      },
      {
        path: '/payouts/create',
        exact: true,
        component: lazy(() => import('src/views/PayoutDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYOUTS]
      },
      {
        path: '/payouts/:id',
        exact: true,
        component: lazy(() => import('src/views/PayoutDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYOUTS]
      },
      {
        path: '/payouts/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/PayoutDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYOUTS]
      },
      {
        path: '/payment-gateways',
        exact: true,
        component: lazy(() => import('src/views/PaymentGatewayList')),
        permissions: [PermissionsEnum.MANAGE_PAYMENTS]
      },
      {
        path: '/payment-gateways/create',
        exact: true,
        component: lazy(() => import('src/views/PaymentGatewayDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYMENTS]
      },
      {
        path: '/payment-gateways/:id',
        exact: true,
        component: lazy(() => import('src/views/PaymentGatewayDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYMENTS]
      },
      {
        path: '/payment-gateways/:gatewayId/methods/create',
        exact: true,
        component: lazy(() => import('src/views/PaymentMethodDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYMENTS]
      },
      {
        path: '/payment-gateways/:gatewayId/methods/:id',
        exact: true,
        component: lazy(() => import('src/views/PaymentMethodDetails')),
        permissions: [PermissionsEnum.MANAGE_PAYMENTS]
      },
      {
        path: '/payment-methods/geolocation',
        exact: true,
        component: lazy(() => import('src/views/PaymentMethodGeolocation')),
        permissions: [PermissionsEnum.MANAGE_PAYMENTS]
      },
      {
        path: '/blacklist',
        exact: true,
        component: lazy(() => import('src/views/Blacklist')),
        permissions: [PermissionsEnum.MANAGE_BLACKLIST_RULES]
      },
      {
        path: '/blacklist/create',
        exact: true,
        component: lazy(() => import('src/views/BlacklistDetails')),
        permissions: [PermissionsEnum.MANAGE_BLACKLIST_RULES]
      },
      {
        path: '/blacklist/:id',
        exact: true,
        component: lazy(() => import('src/views/BlacklistDetails')),
        permissions: [PermissionsEnum.MANAGE_BLACKLIST_RULES]
      },
      {
        path: '/blacklist/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/BlacklistDetails')),
        permissions: [PermissionsEnum.MANAGE_BLACKLIST_RULES]
      },
      {
        path: '/fraud-rules',
        exact: true,
        component: lazy(() => import('src/views/FraudRuleList')),
        permissions: [PermissionsEnum.MANAGE_FRAUD_RULES]
      },
      {
        path: '/fraud-rules/create',
        exact: true,
        component: lazy(() => import('src/views/FraudRuleDetails')),
        permissions: [PermissionsEnum.MANAGE_FRAUD_RULES]
      },
      {
        path: '/fraud-rules/:id',
        exact: true,
        component: lazy(() => import('src/views/FraudRuleDetails')),
        permissions: [PermissionsEnum.MANAGE_FRAUD_RULES]
      },
      {
        path: '/fraud-rules/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/FraudRuleDetails')),
        permissions: [PermissionsEnum.MANAGE_FRAUD_RULES]
      },
      {
        path: '/game-items',
        exact: true,
        component: lazy(() => import('src/views/GameItemList')),
        permissions: [PermissionsEnum.MANAGE_GAME_ITEMS]
      },
      {
        path: '/game-items/create',
        exact: true,
        component: lazy(() => import('src/views/GameItemDetails')),
        permissions: [PermissionsEnum.MANAGE_GAME_ITEMS]
      },
      {
        path: '/game-items/:id',
        exact: true,
        component: lazy(() => import('src/views/GameItemDetails')),
        permissions: [PermissionsEnum.MANAGE_GAME_ITEMS]
      },
      {
        path: '/game-items/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/GameItemDetails')),
        permissions: [PermissionsEnum.MANAGE_GAME_ITEMS]
      },
      {
        path: '/management/shifts',
        exact: true,
        component: lazy(() => import('src/views/ShiftManagementList')),
        permissions: [PermissionsEnum.MANAGE_SHIFTS]
      },
      {
        path: '/management/shifts/:id',
        exact: true,
        component: lazy(() => import('src/views/ShiftManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_SHIFTS]
      },
      {
        path: '/management/shifts/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/ShiftManagementDetails')),
        permissions: [PermissionsEnum.MANAGE_SHIFTS]
      },
      {
        path: '/vouchers',
        exact: true,
        component: lazy(() => import('src/views/VoucherList')),
        permissions: [PermissionsEnum.MANAGE_DISCOUNTS]
      },
      {
        path: '/vouchers/create',
        exact: true,
        component: lazy(() => import('src/views/VoucherDetails')),
        permissions: [PermissionsEnum.MANAGE_DISCOUNTS]
      },
      {
        path: '/vouchers/:id',
        exact: true,
        component: lazy(() => import('src/views/VoucherDetails')),
        permissions: [PermissionsEnum.MANAGE_DISCOUNTS]
      },
      {
        path: '/vouchers/:id/:tab',
        exact: true,
        component: lazy(() => import('src/views/VoucherDetails')),
        permissions: [PermissionsEnum.MANAGE_DISCOUNTS]
      },
      {
        path: '/loyalty-program',
        exact: true,
        component: lazy(() => import('src/views/LoyaltyLevelList')),
        permissions: [PermissionsEnum.MANAGE_LOYALTY_PROGRAM]
      },
      {
        path: '/loyalty-program/:tab',
        exact: true,
        component: lazy(() => import('src/views/LoyaltyLevelList')),
        permissions: [PermissionsEnum.MANAGE_LOYALTY_PROGRAM]
      },
      {
        path: '/loyalty-program/levels/create',
        exact: true,
        component: lazy(() => import('src/views/LoyaltyLevelDetails')),
        permissions: [PermissionsEnum.MANAGE_LOYALTY_PROGRAM]
      },
      {
        path: '/loyalty-program/levels/:id',
        exact: true,
        component: lazy(() => import('src/views/LoyaltyLevelDetails')),
        permissions: [PermissionsEnum.MANAGE_LOYALTY_PROGRAM]
      },
      {
        path: '/menus',
        exact: true,
        component: lazy(() => import('src/views/NavigationList')),
        permissions: [PermissionsEnum.MANAGE_MENUS]
      },
      {
        path: '/menus/:menuId',
        exact: true,
        component: lazy(() => import('src/views/NaviationDetails')),
        permissions: [PermissionsEnum.MANAGE_MENUS]
      },
      {
        path: '/menus/:menuId/:itemId',
        exact: true,
        component: lazy(() => import('src/views/NaviationDetails')),
        permissions: [PermissionsEnum.MANAGE_MENUS]
      },
      {
        path: '/products/:type',
        exact: true,
        component: lazy(() => import('src/views/ProductList'))
      },
      {
        path: '/products/:type/create',
        exact: true,
        component: lazy(() => import('src/views/ProductDetails'))
      },
      {
        path: '/products/:type/:id',
        exact: true,
        component: lazy(() => import('src/views/ProductDetails'))
      },
      {
        path: '/products/:type/:id/analytics',
        exact: true,
        component: lazy(() => import('src/views/ProductAnalytics'))
      },
      {
        path: '/products/:type/:id/variants/create',
        exact: true,
        component: lazy(() => import('src/views/ProductDetails/VariantDetails'))
      },
      {
        path: '/products/:type/:id/variants/:variantId',
        exact: true,
        component: lazy(() => import('src/views/ProductDetails/VariantDetails'))
      },
      {
        path: '/overview',
        exact: true,
        component: OverviewView
      },
      {
        path: '/settings',
        exact: true,
        component: lazy(() => import('src/views/Settings')),
        permissions: [PermissionsEnum.MANAGE_SETTINGS]
      },
      {
        path: '/settings/:tab',
        exact: true,
        component: lazy(() => import('src/views/Settings')),
        permissions: [PermissionsEnum.MANAGE_SETTINGS]
      },
      {
        path: '/errors',
        component: ErrorLayout,
        routes: [
          {
            path: '/errors/error-401',
            exact: true,
            component: lazy(() => import('src/views/Error401'))
          },
          {
            path: '/errors/error-403',
            exact: true,
            component: lazy(() => import('src/views/Error403'))
          },
          {
            path: '/errors/error-404',
            exact: true,
            component: lazy(() => import('src/views/Error404'))
          },
          {
            path: '/errors/error-500',
            exact: true,
            component: lazy(() => import('src/views/Error500'))
          },
          {
            component: () => <Redirect to="/errors/error-404" />
          }
        ]
      },
      {
        component: () => <Redirect to="/errors/error-404" />
      }
    ]
  }
];
