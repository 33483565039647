export const OrderStatusEnum = {
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  PARTIAL_DELIVERY: 'PARTIAL_DELIVERY',
  DELIVERED: 'DELIVERED',
  DELIVERED_GOLD: 'DELIVERED_GOLD',
  DELIVERED_ITEM: 'DELIVERED_ITEM',
  SOLD_FOR_MONEY: 'SOLD_FOR_MONEY',
  SOLD_FOR_POINTS: 'SOLD_FOR_POINTS',
  CANCELED: 'CANCELED',
  REFUNDED: 'REFUNDED',
  EXPIRED: 'EXPIRED'
}

export const PaymentChargeStatus = {
  COMPLETE: 'COMPLETE',
  CREATED: 'CREATED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  NEW: 'NEW',
  DISPUTE: 'DISPUTE',
  FAILED: 'FAILED',
  BLOCKED: 'BLOCKED',
  COMPLAINT: 'COMPLAINT',
  CHARGEBACK: 'CHARGEBACK',
  UNKNOWN: 'UNKNOWN',
  REVERSED: 'REVERSED',
  CANCELED: 'CANCELED',
  PARTIALLY_REFUNDED: 'PARTIALLY_REFUNDED',
  EXPIRED: 'EXPIRED',
  REFUNDED: 'REFUNDED'
}

export const AvailabilityStatus = {
  PUBLISHED: 'PUBLISHED',
  HIDDEN: 'HIDDEN'
}
export const RewardLabel = {
  DEMO_CHEST: 'DEMO_CHEST',
  REGULAR_CHEST: 'REGULAR_CHEST',
  GOLD_PRIZE: 'GOLD_PRIZE',
  ITEM_PRIZE: 'ITEM_PRIZE',
  ION_CHEST: 'ION_CHEST'
}

export const PayoutStatus = {
  CREATED: 'CREATED',
  // PROCESSING: 'PROCESSING',
  CANCELED: 'CANCELED',
  COMPLETED: 'COMPLETED'
}

export const PayoutType = {
  DIRECT_TRADE: 'DIRECT_TRADE',
  RESELL: 'RESELL',
  OVERPAID: 'OVERPAID',
  NEGOTIATED: 'NEGOTIATED',
  ITEMS: 'ITEMS',
  REWARD_BUYBACK: "REWARD_BUYBACK"
}

export const ProductTypeEnum = {
  GOLD: 'GOLD',
  ACCOUNT: 'ACCOUNT',
  PRIZE: 'PRIZE',
  LOYALTY_POINTS: 'LOYALTY_POINTS',
  CUSTOM_PAYMENT: 'CUSTOM_PAYMENT',
  GAME_ITEM: 'GAME_ITEM',
  SERIALS: 'SERIALS',
  ACCOUNT_RENTAL: 'ACCOUNT_RENTAL'
}

export const AnalyticsProductTypeEnum = {
  GOLD: 'GOLD',
  ACCOUNT: 'ACCOUNTS',
  GAME_ITEM: 'GAME_ITEMS',
  SERIALS: 'SERIALS',
  CUSTOM_PAYMENT: 'CUSTOM_PAYMENT',
  LOYALTY_POINTS: 'LOYALTY_POINTS',
  PRIZES: 'PRIZES'
}

export const AccountProductOrderField = {
  PRICE: 'PRICE',
  DISCOUNT_PRICE: 'DISCOUNT_PRICE'
}

export const GameItemProductOrderField = {
  NAME: 'NAME',
  PRICE: 'PRICE',
  GOLD_PRICE: 'GOLD_PRICE',
  DISCOUNT_PRICE: 'DISCOUNT_PRICE',
  TOTAL_BOUGHT: 'TOTAL_BOUGHT',
  TOTAL_BOUGHT_IN_30_DAYS: 'TOTAL_BOUGHT_IN_30_DAYS'
}

export const PrizeProductOrderField = {
  PRICE: 'PRICE',
  TOTAL_REDEEMED: 'TOTAL_REDEEMED',
  TOTAL_REDEEMED_IN_30_DAYS: 'TOTAL_REDEEMED_IN_30_DAYS',
  GOLD_AVG: 'GOLD_AVG',
  POINT_PRICE: 'POINT_PRICE'
}

export const CustomerOrderField = {
  SPENT_AMOUNT: 'SPENT_AMOUNT',
  ORDERS_COUNT: 'ORDERS_COUNT',
  REWARD_ORDERS_COUNT: 'REWARD_ORDERS_COUNT',
  LAST_PAYMENT_DATE: 'LAST_PAYMENT_DATE',
  LAST_ACTIVE_DATE: 'LAST_ACTIVE_DATE',
  JOINED_DATE: 'JOINED_DATE',
  LOYALTY_LEVEL: 'LOYALTY_LEVEL',
  POINTS_AMOUNT: 'POINTS_AMOUNT'
}

export const VoucherOrderField = {
  CODE: 'CODE',
  DISCOUNT: 'DISCOUNT',
  START_DATE: 'START_DATE',
  END_DATE: 'END_DATE',
  REVENUE: 'REVENUE',
  USED: 'USED',
  MIN_SPEND_AMOUNT: 'MIN_SPEND_AMOUNT'
}

export const OrderDirection = {
  ASC: 'ASC',
  DESC: 'DESC'
}

export const OrderEventsEnum = {
  PLACED: 'PLACED',
  ORDER_MARKED_AS_PAID: 'ORDER_MARKED_AS_PAID',
  CANCELED: 'CANCELED',
  ORDER_FULLY_PAID: 'ORDER_FULLY_PAID',
  UPDATED: 'UPDATED',
  STATUS_UPDATED: 'STATUS_UPDATED',
  EMAIL_SENT: 'EMAIL_SENT',
  PRIZE_REFUNDED: 'PRIZE_REFUNDED',
  PAYMENT_AUTHORIZED: 'PAYMENT_AUTHORIZED',
  PAYMENT_CAPTURED: 'PAYMENT_CAPTURED',
  PAYMENT_REFUNDED: 'PAYMENT_REFUNDED',
  PAYMENT_VOIDED: 'PAYMENT_VOIDED',
  PAYMENT_IPN: 'PAYMENT_IPN',
  FULFILLMENT_CANCELED: 'FULFILLMENT_CANCELED',
  FULFILLMENT_RESTOCKED_ITEMS: 'FULFILLMENT_RESTOCKED_ITEMS',
  FULFILLMENT_FULFILLED_ITEMS: 'FULFILLMENT_FULFILLED_ITEMS',
  NOTE_ADDED: 'NOTE_ADDED',
  IPQS_RECEIVED: 'IPQS_RECEIVED',
  MAXMIND_INSIGHTS_DATA: 'MAXMIND_INSIGHTS_DATA',
  MAXMIND_SCORE_DATA: 'MAXMIND_SCORE_DATA',
  IPQS_DEVICE_FINGERPRINT_DATA: 'IPQS_DEVICE_FINGERPRINT_DATA',
  OTHER: 'OTHER'
}

export const GameVersionEnum = {
  OSRS: 'OSRS',
  RS3: 'RS3'
}

export const PrizeVariantLabelEnum = {
  VERY_RARE: 'VERY_RARE',
  RARE: 'RARE',
  UNCOMMON: 'UNCOMMON',
  COMMON: 'COMMON'
}

export const PrizeVariantRewardTypeEnum = {
  GOLD: 'GOLD',
  ITEM: 'ITEM'
}

export const DiscountValueTypeEnum = {
  FIXED: 'FIXED',
  PERCENTAGE: 'PERCENTAGE'
}

export const StaffLimitationsEnum = {
  PAYOUTS: 'PAYOUTS',
  POINTS: 'POINTS'
}

export const VoucherTypeEnum = {
  ENTIRE_ORDER: 'ENTIRE_ORDER',
  EXTRA_GOLD: 'EXTRA_GOLD',
  EXTRA_LOYALTY_POINTS: 'EXTRA_LOYALTY_POINTS',
  SPECIFIC_PRODUCT: 'SPECIFIC_PRODUCT',
  CATEGORIES: 'CATEGORIES'
}

export const CustomerEventsEnum = {
  ACCOUNT_CREATED: 'ACCOUNT_CREATED',
  PASSWORD_RESET_LINK_SENT: 'PASSWORD_RESET_LINK_SENT',
  PASSWORD_RESET: 'PASSWORD_RESET',
  PASSWORD_CHANGED: 'PASSWORD_CHANGED',
  LOYALTY_LEVEL_UPDATED: 'LOYALTY_LEVEL_UPDATED',
  PLACED_ORDER: 'PLACED_ORDER',
  NOTE_ADDED_TO_ORDER: 'NOTE_ADDED_TO_ORDER',
  CUSTOMER_DELETED: 'CUSTOMER_DELETED',
  EMAIL_ASSIGNED: 'EMAIL_ASSIGNED',
  NAME_ASSIGNED: 'NAME_ASSIGNED',
  ORDERS_ASSIGNED: 'ORDERS_ASSIGNED',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  FAILED_LOGIN_ATTEMPT: 'FAILED_LOGIN_ATTEMPT',
  NOTE_ADDED: 'NOTE_ADDED'
}

export const PayoutProductTypeEnum = {
  OSRS: 'OSRS',
  RS3: 'RS3',
  OTHER: 'OTHER'
}

export const GatewayEnum = {
  MANUAL: 'MANUAL',
  PAYPAL: 'PAYPAL',
  G2APAY: 'G2APAY',
  SKRILL: 'SKRILL',
  COINBASE: 'COINBASE',
  BLOCKCHAIN: 'BLOCKCHAIN',
  COINGATE: 'COINGATE',
  FASTERPAY: 'FASTERPAY',
  MISTERTANGO: 'MISTERTANGO',
  CARDPAY: 'CARDPAY',
  EMERCHANTPAY: 'EMERCHANTPAY',
  TRUSTPAY: 'TRUSTPAY',
  LOCALBITCOINS: 'LOCALBITCOINS',
  DEBT: 'DEBT'
}

export const PaymentActionEnum = {
  CHECKOUT: 'CHECKOUT',
  REFUND: 'REFUND',
  PAYOUT: 'PAYOUT',
  IPN: 'IPN'
}

export const ConfigurationTypeField = {
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  SECRET: 'SECRET',
  PASSWORD: 'PASSWORD'
}

export const RuleFieldTypeField = {
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
  FLOAT: 'FLOAT'
}

export const CurrencyEnum = {
  USD: 'USD',
  GBP: 'GBP',
  EUR: 'EUR',
  CAD: 'CAD',
  SEK: 'SEK',
  NOK: 'NOK',
  DKK: 'DKK'
}

export const PermissionsEnum = {
  MANAGE_BLACKLIST_RULES: 'MANAGE_BLACKLIST_RULES',
  MANAGE_FRAUD_RULES: 'MANAGE_FRAUD_RULES',
  MANAGE_GAME_ITEMS: 'MANAGE_GAME_ITEMS',
  MANAGE_BLOG: 'MANAGE_BLOG',
  MANAGE_DISCOUNTS: 'MANAGE_DISCOUNTS',
  MANAGE_LOYALTY_PROGRAM: 'MANAGE_LOYALTY_PROGRAM',
  MANAGE_MENUS: 'MANAGE_MENUS',
  MANAGE_ORDERS_ACCOUNTS_RENTAL: 'MANAGE_ORDERS_ACCOUNTS_RENTAL',
  MANAGE_ORDERS_ACCOUNTS: 'MANAGE_ORDERS_ACCOUNTS',
  MANAGE_ORDERS_GAME_ITEMS: 'MANAGE_ORDERS_GAME_ITEMS',
  MANAGE_ORDERS_GOLD: 'MANAGE_ORDERS_GOLD',
  MANAGE_ORDERS_POINTS: 'MANAGE_ORDERS_POINTS',
  MANAGE_ORDERS_CUSTOM_PAYMENTS: 'MANAGE_ORDERS_CUSTOM_PAYMENTS',
  MANAGE_ORDERS_SERIALS: 'MANAGE_ORDERS_SERIALS',
  MANAGE_ORDERS_PRIZES: 'MANAGE_ORDERS_PRIZES',
  MANAGE_PAGES: 'MANAGE_PAGES',
  MANAGE_PAYMENTS: 'MANAGE_PAYMENTS',
  MANAGE_PAYOUTS: 'MANAGE_PAYOUTS',
  MANAGE_PRODUCTS_ACCOUNTS: 'MANAGE_PRODUCTS_ACCOUNTS',
  MANAGE_PRODUCTS_ACCOUNTS_RENTAL: 'MANAGE_PRODUCTS_ACCOUNTS_RENTAL',
  MANAGE_PRODUCTS_GAME_ITEMS: 'MANAGE_PRODUCTS_GAME_ITEMS',
  MANAGE_PRODUCTS_SERIALS: 'MANAGE_PRODUCTS_SERIALS',
  MANAGE_PRODUCTS_GOLD: 'MANAGE_PRODUCTS_GOLD',
  MANAGE_PRODUCTS_POINTS: 'MANAGE_PRODUCTS_POINTS',
  MANAGE_PRODUCTS_PRIZES: 'MANAGE_PRODUCTS_PRIZES',
  MANAGE_PRODUCTS_CUSTOM_PAYMENTS: 'MANAGE_PRODUCTS_CUSTOM_PAYMENTS',
  MANAGE_SETTINGS: 'MANAGE_SETTINGS',
  MANAGE_SHIFTS: 'MANAGE_SHIFTS',
  MANAGE_STAFF: 'MANAGE_STAFF',
  MANAGE_USERS: 'MANAGE_USERS',
  MANAGE_ORDERS_ANALYTICS: 'MANAGE_ORDERS_ANALYTICS'
}

export const BlacklistRuleFieldEnum = {
  EMAIL: 'EMAIL',
  PAYER_EMAIL: 'PAYER_EMAIL',
  PAYER_NAME: 'PAYER_NAME',
  RSN: 'RSN',
  IP_ADDRESS: 'IP_ADDRESS',
  PAYPAL_ID: 'PAYPAL_ID',
  MAXMIND_DEVICE_ID: 'MAXMIND_DEVICE_ID'
}

export const FraudRuleFieldEnum = {
  ISP: 'ISP',
  CONNECTION_TYPE: 'CONNECTION_TYPE',
  RISK_SCORE: 'RISK_SCORE',
  FRAUD_SCORE: 'FRAUD_SCORE',
  AMOUNT: 'AMOUNT',
  IS_3D: 'IS_3D',
  IS_VPN: 'IS_VPN',
  IS_TOR: 'IS_TOR',
  IS_PROXY: 'IS_PROXY',
  BOT_STATUS: 'BOT_STATUS',
  RECENT_ABUSE: 'RECENT_ABUSE',
  IS_100_FRAUD: 'IS_100_FRAUD',
  USER_STATUS: 'USER_STATUS'
}

export const FilterPageEnum = {
  ORDERS_GOLD: 'ORDERS_GOLD',
  ORDERS_ACCOUNTS: 'ORDERS_ACCOUNTS',
  ORDERS_PRIZES: 'ORDERS_PRIZES'
}

export const MenuItemLabelEnum = {
  NEW: 'NEW'
}

export const RuleFieldType = {
  BOOLEAN: 'BOOLEAN',
  STRING: 'STRING',
  FLOAT: 'FLOAT',
  LIST: 'LIST'
}

export const MaxmindUserType = {
  RESIDENTIAL: 'RESIDENTIAL',
  HOSTING: 'HOSTING',
  CELLULAR: 'CELLULAR'
}

export const ChartIntervalEnum = {
  DAY: 'DAY',
  WEEK: 'WEEK',
  MONTH: 'MONTH'
}

export const PricePeriodEnum = {
  WEEK: 'WEEK',
  MONTH: 'MONTH',
  THREE_MONTH: 'THREE_MONTH',
  SIX_MONTH: 'SIX_MONTH'
}

export const SerialsProductEnum = {
  MEMBERSHIP: 'MEMBERSHIP',
  RUNECOINS: 'RUNECOINS'
}

export const SerialsVariantStatusEnum = {
  AVAILABLE: 'AVAILABLE',
  SOLD: 'SOLD',
  RESERVED: 'RESERVED'
}

export const CustomerPointsEventsEnum = {
  REGISTRATION: 'REGISTRATION',
  REGULAR_PURCHASE: 'REGULAR_PURCHASE',
  POINTS_PURCHASE: 'POINTS_PURCHASE',
  REFUNDED: 'REFUNDED',
  PRIZE_REDEMPTION: 'PRIZE_REDEMPTION',
  MANUALLY_ADDED: 'MANUALLY_ADDED',
  DAILY_RECALCULATION: 'DAILY_RECALCULATION',
  GUEST_ORDER_ASSIGNMENT: 'GUEST_ORDER_ASSIGNMENT'
}

export const FraudRuleFlagType = {
  RED: 'RED',
  YELLOW: 'YELLOW'
}

export const CategoryLabelTypeEnum = {
  ITEM: 'ITEM',
  PRIZE: 'PRIZE',
  ACCOUNT: 'ACCOUNT'
}

export const CustomPaymentOptionEnum = {
  SERVICE_PROVIDER: 'SERVICE_PROVIDER',
  SERVICE_TYPE: 'SERVICE_TYPE'
}

export const ShiftActivityEnum = {
  PROFIT: 'profit',
  PROFIT_OSRS: 'profit_osrs',
  PROFIT_RS3: 'profit_rs3',
  TOTAL_SALES: 'total_sales',
  TOTAL_OSRS_SALES: 'total_osrs_sales',
  TOTAL_RS3_SALES: 'total_rs3_sales',
  TOTAL_SENT: 'total_sent',
  TOTAL_OSRS_SENT: 'total_osrs_sent',
  TOTAL_RS3_SENT: 'total_rs3_sent',
  DELIVERED_OSRS_GOLD: 'delivered_osrs_gold',
  DELIVERED_RS3_GOLD: 'delivered_rs3_gold',
  DELIVERED_OSRS_GOLD_OF_PRIZE: 'delivered_osrs_gold_of_prize',
  DELIVERED_RS3_GOLD_OF_PRIZE: 'delivered_rs3_gold_of_prize',

  PURCHASED_OSRS_GOLD: 'purchased_osrs_gold',
  PURCHASED_RS3_GOLD: 'purchased_rs3_gold',
  OSRS_UNIT_PRICE: 'osrs_unit_price',
  RS3_UNIT_PRICE: 'rs3_unit_price',

  AVERAGE_OSRS_UNIT_PRICE: 'average_osrs_unit_price',
  AVERAGE_RS3_UNIT_PRICE: 'average_rs3_unit_price',
  GOLD_ORDERS_COUNT: 'gold_orders_count',
  PRIZE_ORDERS_COUNT: 'prize_orders_count',
  ACCOUNT_ORDERS_COUNT: 'account_orders_count',
  ITEMS_ORDERS_COUNT: 'items_orders_count'
}

export const FeedbackProductTypeEnum = {
  OSRS_ACCOUNT: 'OSRS_ACCOUNT',
  OSRS_ITEM: 'OSRS_ITEM',
  OSRS_GOLD: 'OSRS_GOLD',
  RS3_GOLD: 'RS3_GOLD',
  SELL_RS_GOLD: 'SELL_RS_GOLD',
  REWARDS: 'REWARDS',
  SERVICES: 'SERVICES'
}

// export const FeedbackPageTypeEnum = {
//   HOMEPAGE: "index",
//   BUY_OSRS_GOLD: "Buy-OSRS-Gold",
//   BUT_RS3_GOLD: "buy-rs3-gold",
//   ITEMS: "osrs-items",
//   ACCOUNTS: "buy-osrs-account",
//   REWARDS: "loyalty-rewards",
//   SELL_RS_GOLD: "sell-rs-gold",
//   CUSTOM_PAYMENT: "custom-payment",
//   BLOG: "blog"
// };

export const FeedbackPageTypeEnum = {
  HOMEPAGE: 'HOMEPAGE',
  BUY_OSRS_GOLD: 'BUY_OSRS_GOLD',
  BUY_RS3_GOLD: 'BUY_RS3_GOLD',
  ITEMS: 'ITEMS',
  ACCOUNTS: 'ACCOUNTS',
  REWARDS: 'REWARDS',
  SELL_RS_GOLD: 'SELL_RS_GOLD',
  CUSTOM_PAYMENT: 'CUSTOM_PAYMENT',
  BLOG: 'BLOG'
}

export const UserTypeEnum = {
  GUEST: 'Guest',
  REGISTERED: 'Registered'
}
