import gql from 'graphql-tag';

export const fragmentUser = gql`
  fragment User on User {
    id
    email
    fullName
    isStaff
    isSuperuser
    isAdmin
    is2faEnabled
    isActive
    role
    userPermissions {
      code
      name
    }
  }
`;

export const tokenCreateMutation = gql`
  ${fragmentUser}
  mutation TokenCreate($email: String!, $password: String!) {
    tokenCreate(email: $email, password: $password) {
      token
      errors {
        field
        message
      }
      user {
        ...User
      }
    }
  }
`;

export const tokenVerifyMutation = gql`
  ${fragmentUser}
  mutation VerifyToken($token: String!) {
    tokenVerify(token: $token) {
      payload
      user {
        ...User
      }
    }
  }
`;

export const TokenRefreshMutation = gql`
  mutation TokenRefresh($refreshToken: String!) {
    tokenRefresh(refreshToken: $refreshToken) {
      token
      refreshToken
    }
  }
`;
