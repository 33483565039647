import { useEffect } from 'react';
import { gqlErrKey } from '../core/config';
import { useSnackbar } from 'notistack';

function NotificationReader() {
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    // listen for custom gqlError event
    document.addEventListener(
      'gqlError',
      e => {
        let errors = localStorage.getItem(gqlErrKey);
        if (errors) {
          errors = JSON.parse(errors);
          errors.map(error => enqueueSnackbar(error, { variant: 'error' }));
          localStorage.removeItem(gqlErrKey);
        }
      },
      false
    );
  });

  return null;
}

export default NotificationReader;
