import React, { useState } from 'react';
import { makeStyles, Paper, Button, Box } from '@material-ui/core';
import Timer from 'react-compound-timer';
import { useMutation, useQuery } from '@apollo/react-hooks';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { ManagerActiveShiftQuery } from './queries';
import { formatDuration, maybe } from '../../../core/utils';
import { ShiftCloseMutation, ShiftStartMutation } from './mutations';
import GenericDialog from '../../../components/GenericDialog';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: 1200,
    position: 'fixed',
    left: '20%',
    border: '1px solid #111',
    boxShadow: '0 1px 10px 5px darkgrey',
    textAlign: 'center'
  },
  isOpened: {
    '&:hover': {
      cursor: 'pointer'
    }
  },
  content: {
    padding: theme.spacing(1)
  },
  timer: {
    letterSpacing: 4,
    fontSize: '1rem',
    color: '#333333',
    textShadow: '0 0 6px #ccc'
  },
  timerExpanded: {
    letterSpacing: 4,
    fontSize: '1.45rem',
    color: '#333333',
    textShadow: '0 0 6px #ccc'
  },
  button: {
    color: '#ffffff',
    margin: 5
  }
}));

const errorMsg = 'Oops, something went wrong! Restart the page and try again.';

const ShiftWidget = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [openCloseModal, setCloseOpenModal] = useState(false);
  const [showButtons, setShowButtons] = useState(false);

  const { data: ShiftData, loading } = useQuery(ManagerActiveShiftQuery);
  const shift = maybe(() => ShiftData.shift, null);
  const initialTime = shift
    ? moment()
        .diff(shift.created)
        .valueOf()
    : 0;

  const [shiftStart] = useMutation(ShiftStartMutation, {
    update: (cache, { data }) => {
      cache.writeQuery({
        query: ManagerActiveShiftQuery,
        data: { shift: data.shiftStart.shift }
      });
    },
    onError: error => enqueueSnackbar(errorMsg, { variant: 'error' })
  });

  const [shiftClose] = useMutation(ShiftCloseMutation, {
    update: (cache, { data }) => {
      // setInitialTime(0);
      cache.writeQuery({
        query: ManagerActiveShiftQuery,
        data: { shift: null }
      });
    },
    onError: error => enqueueSnackbar(errorMsg, { variant: 'error' })
  });

  const handleShiftStarted = async startFunction => {
    const { data: CreateData } = await shiftStart();
    const errors = maybe(() => CreateData.shiftStart.shift.errors, []);
    if (errors.length > 0) {
      enqueueSnackbar(errorMsg, { variant: 'error' });
      return null;
    }
    startFunction();
    setShowButtons(false);
    enqueueSnackbar('Shift successfully started.', { variant: 'success' });
  };
  const handleShiftClosed = async (closeFunction, resetFunction) => {
    const { data: CloseData } = await shiftClose();
    const errors = maybe(() => CloseData.shiftClose.shift.errors, []);
    if (errors.length > 0) {
      enqueueSnackbar(errorMsg, { variant: 'error' });
      return null;
    }
    await resetFunction(0);
    await closeFunction();
    enqueueSnackbar('Shift successfully close.', { variant: 'success' });
  };

  if (initialTime === null) {
    return 'Loading';
  }

  return (
    <Paper className={classes.root}>
      {loading ? (
        'Loading...'
      ) : (
        <Timer
          initialTime={initialTime}
          startImmediately={shift}
          formatValue={value => `${value < 10 ? `0${value}` : value}`}
        >
          {({ start, stop, reset, getTime, setTime }) => (
            <>
              <div
                className={clsx(
                  classes.content,
                  !showButtons && classes.isOpened
                )}
              >
                <div
                  className={clsx(
                    classes.timer,
                    showButtons && classes.timerExpanded
                  )}
                  onClick={() => setShowButtons(true)}
                >
                  {formatDuration(getTime())}
                </div>
                {showButtons && (
                  <Box className={classes.buttons}>
                    {!shift && (
                      <Button
                        onClick={async () => {
                          await handleShiftStarted(start);
                        }}
                        variant="contained"
                        color="primary"
                        title="Start new shift"
                        className={classes.button}
                      >
                        Start
                      </Button>
                    )}
                    {shift && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setCloseOpenModal(true)}
                        title="Close current shift"
                        className={classes.button}
                      >
                        Stop
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => setShowButtons(false)}
                      title="Hide shift widget"
                      className={classes.button}
                    >
                      Hide
                    </Button>
                    {shift && (
                      <div>
                        <div>Gold orders: {shift.goldOrdersCount}</div>
                        <div>Items orders: {shift.itemsOrdersCount}</div>
                        <div>Prize orders: {shift.prizeOrdersCount}</div>
                        <div>Sales: {shift.totalSales.localized}</div>
                        <div>Send: {shift.totalSent.localized}</div>
                      </div>
                    )}
                  </Box>
                )}
              </div>
              <GenericDialog
                isOpen={openCloseModal}
                title="Confirm shift close action!"
                desc="Stop shift only once you have checked that there are no unpaid payments left"
                handleCloseDialog={() => setCloseOpenModal(false)}
                handleConfirmDialog={async () => {
                  await handleShiftClosed(stop, setTime);
                  await setCloseOpenModal(false);
                }}
              />
            </>
          )}
        </Timer>
      )}
    </Paper>
  );
};
ShiftWidget.propTypes = {};

export default ShiftWidget;
