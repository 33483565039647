import React from 'react';
import { Router } from 'react-router-dom';
import { renderRoutes } from 'react-router-config';
import { createBrowserHistory } from 'history';
import { SnackbarProvider } from 'notistack';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { ApolloProvider } from '@apollo/react-hooks';
import { create } from 'jss';
import privateRoutes from './privateRoutes';
import GoogleAnalytics from './components/GoogleAnalytics';
import ScrollReset from './components/ScrollReset';
import StylesProvider from './components/StylesProvider';
import './mixins/chartjs';
import './mixins/moment';
import './mixins/validate';
import './mixins/prismjs';
import './mock';
import './assets/scss/main.scss';
import rtl from 'jss-rtl';
import apolloClient from './apollo';
import AuthProvider from './auth';
import LoadingSpinner from './components/LoadingSpinner';
import useSettings from 'src/hooks/useSettings';
import { createTheme } from 'src/theme';
import {
  createStyles,
  makeStyles,
  jssPreset,
  ThemeProvider
} from '@material-ui/core';
import Config from './components/Config';
import { validateRoutePermissions } from './auth/utils';
import { FilterProvider } from './context/FilterContext';
import Error401 from './views/Error401';
import ErrorBoundary from './components/ErrorBoundary';
import { HiddableColumnsProvider } from './context/HiddableColumnsContext';
import NotificationReader from './components/NotificationReader';

const history = createBrowserHistory();
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '*': {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0
      },
      html: {
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale',
        height: '100%',
        width: '100%'
      },
      body: {
        height: '100%',
        width: '100%'
      },
      '#root': {
        height: '100%',
        width: '100%'
      },
      a: {
        color: '#039BE5',
        '&:hover': {
          opacity: 0.8
        }
      },
      '.link': {
        color: '#039BE5',
        '&:hover': {
          opacity: 0.8,
          cursor: 'pointer'
        }
      }
    }
  })
);

function App() {
  useStyles();
  const { settings } = useSettings();
  return (
    <ErrorBoundary>
      <ApolloProvider client={apolloClient}>
        <ThemeProvider theme={createTheme(settings)}>
          <StylesProvider jss={jss}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <SnackbarProvider
                dense
                // autoHideDuration={2000}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
              >
                {/*<WebsocketProvider>*/}
                <Router history={history}>
                  <ScrollReset />
                  <Config />
                  <GoogleAnalytics />
                  <NotificationReader />
                  <FilterProvider>
                    <HiddableColumnsProvider>
                      <AuthProvider>
                        {({ hasToken, isAuthenticated, user }) =>
                          isAuthenticated ? (
                            <>
                              {/* {renderRoutes(privateRoutes)} */}
                              {renderRoutes(
                                validateRoutePermissions(
                                  privateRoutes,
                                  user.userPermissions
                                )
                              )}
                              {/* {renderRoutes(errorRoutes)} */}
                            </>
                          ) : hasToken && !isAuthenticated ? (
                            <LoadingSpinner />
                          ) : (
                            <Error401 hasToken={hasToken} />
                            // <Auth />
                          )
                        }
                      </AuthProvider>
                    </HiddableColumnsProvider>
                  </FilterProvider>
                </Router>
                {/*</WebsocketProvider>*/}
              </SnackbarProvider>
            </MuiPickersUtilsProvider>
          </StylesProvider>
        </ThemeProvider>
      </ApolloProvider>
    </ErrorBoundary>
  );
}

export default App;
