import React, { useContext } from 'react';
import { PermissibleRender } from '@brainhubeu/react-permissible';
import PropTypes from 'prop-types';
import { UserContext } from '../auth';

// Example of user permissions: ['MANAGE_ORDERS', 'MANAGE_PAYOUTS', 'MANAGE_BLOG'];

function PermissionsGuard({ requiredPermissions, atLeastOnePerm, children, ...other }) {
  const userContext = useContext(UserContext);
  const permissions = Array.from(userContext.user.userPermissions, p => p.code);

  return (
    <PermissibleRender
      userPermissions={permissions}
      requiredPermissions={requiredPermissions}
      oneperm={atLeastOnePerm}
      {...other}
    >
      {children}
    </PermissibleRender>
  );
}

PermissionsGuard.propTypes = {
  children: PropTypes.node,
  requiredPermissions: PropTypes.array.isRequired,
  atLeastOnePerm: PropTypes.bool
};

PermissionsGuard.defaultProps = {
  atLeastOnePerm: false
};

export default PermissionsGuard;
