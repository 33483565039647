import React, { Suspense, useState } from 'react';
import { renderRoutes } from 'react-router-config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import clsx from 'clsx';
import NavBar from './NavBar';
import TopBar from './TopBar';
import ShiftWidget from './ShiftWidget';
import useSettings from '../../hooks/useSettings';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64
  },
  wrapperOpen: {
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 220
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  }
}));

const openNavBarDesktopKey = 'openNavBarDesktop';

function Dashboard({ route }) {
  // case when we don't have openNavBarDesktopDefault val in localStorage
  // openNavBarDesktopDefault = openNavBarDesktopDefault === null ? true : openNavBarDesktopDefault;

  const classes = useStyles();
  const { saveContentRef } = useSettings();

  const openNavBarDesktopKeyValue = localStorage.getItem(openNavBarDesktopKey);
  const openNavBarDesktopDefault =
    openNavBarDesktopKeyValue === 'true' || openNavBarDesktopKeyValue === null;
  const [openNavBarMobile, setOpenNavBarMobile] = useState(false);
  const [openNavBarDesktop, setOpenNavBarDesktop] = useState(
    openNavBarDesktopDefault
  );

  return (
    <div className={classes.root}>
      <ShiftWidget />
      <TopBar
        onOpenNavBarMobile={() => setOpenNavBarMobile(true)}
        onToggleNavBarDesktop={() => {
          localStorage.setItem(openNavBarDesktopKey, !openNavBarDesktop);
          setOpenNavBarDesktop(!openNavBarDesktop);
        }}
      />
      <NavBar
        onMobileClose={() => setOpenNavBarMobile(false)}
        openMobile={openNavBarMobile}
        openDesktop={openNavBarDesktop}
      />
      <div
        className={clsx(
          classes.wrapper,
          openNavBarDesktop && classes.wrapperOpen
        )}
      >
        <div className={classes.contentContainer}>
          <div className={classes.content} ref={r => saveContentRef(r)}>
            <Suspense fallback={<LinearProgress />}>
              {renderRoutes(route.routes)}
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
}

Dashboard.propTypes = {
  route: PropTypes.object
};

export default Dashboard;
