import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

const HiddableColumnsContext = createContext();

export function HiddableColumnsProvider({ children }) {
  const [key, setKey] = useState();
  const [values, setValues] = useState();

  const loadFilterData = _filterKey => {
    if (!_filterKey) return {};
    setKey(_filterKey);
    let columnValues = localStorage.getItem(_filterKey);
    if (values) {
      columnValues = values;
    } else {
      try {
        columnValues = JSON.parse(columnValues);
      } catch (e) {
        console.log(e);
      }
    }
    return { columnValues };
  };

  const handleSaveFilter = valuesToFilter => {
    setValues(valuesToFilter);
    localStorage.setItem(key, JSON.stringify(valuesToFilter));
  };

  const handleClearFilter = () => {
    localStorage.removeItem(key);
  };

  const assignFilterKey = _filterKey => {
    setKey(_filterKey);
  };

  return (
    <HiddableColumnsContext.Provider
      value={{
        setColumnsKey: assignFilterKey,
        getColumns: loadFilterData,
        saveColumns: handleSaveFilter,
        clearColumns: handleClearFilter
      }}
    >
      {children}
    </HiddableColumnsContext.Provider>
  );
}

HiddableColumnsProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const HiddableColumnsConsumer = HiddableColumnsContext.Consumer;

export default HiddableColumnsContext;
