import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useApolloClient } from '@apollo/react-hooks';

const FilterContext = createContext();

export function FilterProvider({ children }) {
  const [filterKey, setFilterKey] = useState();
  const client = useApolloClient();

  const loadFilterData = _filterKey => {
    if (!_filterKey) return {};
    setFilterKey(_filterKey);
    let filterValues = localStorage.getItem(_filterKey);
    let filterTagsValues = localStorage.getItem(`${_filterKey}Tags`);

    try {
      filterValues = JSON.parse(filterValues);
      filterTagsValues = JSON.parse(filterTagsValues);
    } catch (e) {
      console.log(e);
    }

    // // check if we have filter values saved
    // if (filterValues && filterTagsValues) {
    //   // parse saved data and convert it to readable info
    //   filterValues = JSON.parse(filterValues);
    //   filterTagsValues = JSON.parse(filterTagsValues);
    //   // filterTagsValues = objToTags(filterTagsValues);
    // }

    // setFilterValues([filterValues, filterTagsValues]);
    return { filterValues, filterTagsValues };
  };

  const handleSaveFilter = (valuesToFilter, valuesToTags) => {
    localStorage.setItem(filterKey, JSON.stringify(valuesToFilter));
    localStorage.setItem(`${filterKey}Tags`, JSON.stringify(valuesToTags));
    // setFilterKey('as');
  };

  const handleClearFilter = () => {
    client.cache.reset();
    localStorage.removeItem(filterKey);
    localStorage.removeItem(`${filterKey}Tags`);
  };

  const assignFilterKey = _filterKey => {
    setFilterKey(_filterKey);
  };

  return (
    <FilterContext.Provider
      value={{
        setFilterKey: assignFilterKey,
        getFilter: loadFilterData,
        saveFilter: handleSaveFilter,
        clearFilter: handleClearFilter
      }}
    >
      {children}
    </FilterContext.Provider>
  );
}

FilterProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const FilterConsumer = FilterContext.Consumer;

export default FilterContext;
