import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

const GenericDialog = ({
  isOpen,
  title,
  desc,
  handleCloseDialog,
  handleConfirmDialog
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={handleCloseDialog}
      aria-labelledby={`${title}-dialog-title`}
    >
      <DialogTitle id={`${title}-dialog-title`}>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{desc}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary" variant="text">
          Cancel
        </Button>
        {handleConfirmDialog && (
          <Button
            onClick={handleConfirmDialog}
            color="primary"
            variant="contained"
          >
            Confirm
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

GenericDialog.propTypes = {
  isOpen: PropTypes.bool,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  handleCloseDialog: PropTypes.func.isRequired,
  handleConfirmDialog: PropTypes.func
};

GenericDialog.defaultProps = {
  isOpen: false
};

export default GenericDialog;
