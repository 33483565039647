import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';

const useStyles = makeStyles(
  {
    root: {
      alignItems: 'center',
      display: 'flex',
      height: '100vh',
      justifyContent: 'center'
    }
  },
  { name: 'LoginLoading' }
);
const LoadingSpinner = ({ size, classes }) => {
  const _classes = { ...useStyles(), ...classes };
  const _size = size || 128;

  return (
    <div className={_classes.root}>
      <CircularProgress size={_size} />
    </div>
  );
};
LoadingSpinner.displayName = 'LoadingSpinner';
export default LoadingSpinner;
