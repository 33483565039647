import gql from 'graphql-tag';
import { ShiftFragment } from './queries';

export const ShiftStartMutation = gql`
  ${ShiftFragment}
  mutation ShiftStart{
    shiftStart {
      shift {
        ...ShiftFragment
      }
    }
  }
`;

export const ShiftCloseMutation = gql`
  ${ShiftFragment}
  mutation ShiftClose{
    shiftClose {
      shift {
        ...ShiftFragment
      }
    }
  }
`;
