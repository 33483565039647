import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router';
import { useMutation } from '@apollo/react-hooks';
import {
  getAuthToken,
  removeAuthToken,
  removeRefreshToken,
  setAuthToken
} from './utils';
import { tokenVerifyMutation } from './mutations';

export const UserContext = React.createContext({
  loginByToken: undefined,
  logout: undefined,
  user: null
});

const AuthProvider = ({ children }) => {
  const location = useLocation();
  // const client = useApolloClient();
  const [user, setUser] = useState(undefined);
  const isMounted = useRef(true);
  // const [tokenRefreshFn] = useMutation(TokenRefreshMutation, {
  //   onCompleted: ({ tokenRefresh }) => {
  //     setRefreshToken(tokenRefresh.refreshToken);
  //     setAuthToken(tokenRefresh.token);
  //
  //     // resetStore initiate rerun of all failed queries
  //     client.resetStore();
  //
  //     // rerun token verify to get user details
  //     tokenVerifyFn({
  //       variables: {
  //         token: tokenRefresh.token
  //       }
  //     });
  //   },
  //   onError: error => {
  //     console.log('tokenRefresh error', error);
  //     return logout();
  //   }
  // });
  const [tokenVerifyFn] = useMutation(tokenVerifyMutation, {
    onError: error => {
      console.log(error);
      return logout();
    },
    onCompleted: ({ tokenVerify }) => {
      if (!tokenVerify) {
        return logout();
      }
      // const refreshToken = getRefreshToken();
      // // manager should always has refresh token !!!
      // if (!refreshToken) {
      //   return logout();
      // }
      //
      // if (tokenVerify.payload) {
      //   const exp = tokenVerify.payload.exp;
      //   const atm = moment().unix();
      //   // do silent refresh in case if exp time less than 5 minute
      //   const silentRefresh = exp - atm < 60 * 5;
      //   if (silentRefresh) {
      //     console.log('silentRefresh', silentRefresh);
      //     tokenRefreshFn({
      //       variables: { refreshToken }
      //     });
      //   }
      // }
      if (tokenVerify && validateStaff(tokenVerify.user)) {
        tokenVerify.user.permissions = Array.from(
          tokenVerify.user.userPermissions,
          p => p.code
        );
        setUser(tokenVerify.user);
      }
    }
  });

  useEffect(() => {
    // if (isMounted.current) {
    // }
    const token = getAuthToken();
    tokenVerifyFn({
      variables: { token }
    });
    isMounted.current = false;
  }, [location.pathname, tokenVerifyFn]);

  const loginByToken = (token, user) => {
    setUser(user);
    setAuthToken(token);
  };

  const logout = () => {
    setUser(undefined);
    removeAuthToken();
    removeRefreshToken();
    window.location.replace(`${process.env.REACT_APP_FRONTEND_URL}?signin=1`);
  };
  const validateStaff = user => {
    // allow only activated staff users access dashboard !!!
    if (!(user && user.isStaff && user.isActive)) {
      // TODO: show Snackbar here with the reason ???
      logout();
      return false;
    }
    if (user && user.isStaff && !user.is2faEnabled) {
      // redirect manager to settings page to set 2fa
      window.location.replace(
        `${process.env.REACT_APP_FRONTEND_URL}/account/settings?requires2fa=true`
      );
      return false;
    }
    return true;
  };
  const isAuthenticated = !!user;

  return (
    <UserContext.Provider
      value={{
        loginByToken,
        logout,
        user
      }}
    >
      {children({
        hasToken: !!getAuthToken(),
        isAuthenticated,
        user
      })}
    </UserContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.any,
  tokenAuth: PropTypes.array,
  tokenRefresh: PropTypes.array,
  tokenVerify: PropTypes.array
};

// AuthProviderOperations.propTypes = {
//   children: PropTypes.any
// };

export default AuthProvider;
