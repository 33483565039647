/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import BarChartIcon from '@material-ui/icons/BarChart';
import LanguageIcon from '@material-ui/icons/Language';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import PeopleIcon from '@material-ui/icons/People';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PaymentIcon from '@material-ui/icons/Payment';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import FeedbackIcon from '@material-ui/icons/Feedback';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ReceiptIcon from '@material-ui/icons/Receipt';
import BlockIcon from '@material-ui/icons/Block';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import { PermissionsEnum, ProductTypeEnum } from '../../core/enums';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewConfigIcon from '@material-ui/icons/ViewComfy';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';

export default [
  {
    subheader: 'Main',
    items: [
      // {
      //   title: 'Overview',
      //   href: '/overview',
      //   icon: HomeIcon
      // },
      {
        title: 'Orders',
        icon: ShoppingCartIcon,
        href: `/management/orders/${ProductTypeEnum.GOLD.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_GOLD]
      },
      {
        title: 'Send payments',
        href: '/payouts/create',
        icon: PaymentIcon,
        permissions: [PermissionsEnum.MANAGE_PAYOUTS]
      },
      {
        title: 'Payments list',
        href: '/payouts',
        icon: ReceiptIcon,
        permissions: [PermissionsEnum.MANAGE_PAYOUTS]
      },
      {
        title: 'Discount codes',
        href: '/vouchers',
        icon: LoyaltyIcon,
        permissions: [PermissionsEnum.MANAGE_DISCOUNTS]
      },
      {
        title: 'Blacklist',
        href: '/blacklist',
        icon: BlockIcon,
        permissions: [PermissionsEnum.MANAGE_BLACKLIST_RULES]
      },
      {
        title: 'Fraud rules',
        href: '/fraud-rules',
        icon: ErrorOutlineIcon,
        permissions: [PermissionsEnum.MANAGE_FRAUD_RULES]
      },
      {
        title: 'Shift management',
        href: '/management/shifts',
        icon: FolderSharedIcon,
        permissions: [PermissionsEnum.MANAGE_SHIFTS]
      },
      {
        title: 'Products',
        href: '#',
        icon: BarChartIcon,
        permissionsOneOf: [
          PermissionsEnum.MANAGE_PRODUCTS_POINTS,
          PermissionsEnum.MANAGE_PRODUCTS_ACCOUNTS,
          PermissionsEnum.MANAGE_PRODUCTS_PRIZES,
          PermissionsEnum.MANAGE_PRODUCTS_GAME_ITEMS,
          PermissionsEnum.MANAGE_PRODUCTS_ACCOUNTS_RENTAL,
          PermissionsEnum.MANAGE_PRODUCTS_GOLD,
          PermissionsEnum.MANAGE_ORDERS_SERIALS,
          PermissionsEnum.MANAGE_PRODUCTS_CUSTOM_PAYMENTS
        ],
        items: [
          {
            title: 'Gold',
            href: `/products/${ProductTypeEnum.GOLD.toLowerCase()}`,
            permissions: [PermissionsEnum.MANAGE_PRODUCTS_GOLD]
          },
          {
            title: 'Prizes',
            href: `/products/${ProductTypeEnum.PRIZE.toLowerCase()}`,
            permissions: [PermissionsEnum.MANAGE_PRODUCTS_PRIZES]
          },
          {
            title: 'Accounts',
            href: `/products/${ProductTypeEnum.ACCOUNT.toLowerCase()}`,
            permissions: [PermissionsEnum.MANAGE_PRODUCTS_ACCOUNTS]
          },
          {
            title: 'Loyalty points',
            href: `/products/${ProductTypeEnum.LOYALTY_POINTS.toLowerCase()}`,
            permissions: [PermissionsEnum.MANAGE_PRODUCTS_POINTS]
          },
          // {
          //   title: 'Account rental',
          //   href: `/products/${ProductTypeEnum.ACCOUNT_RENTAL.toLowerCase()}`,
          //   permissions: [PermissionsEnum.MANAGE_PRODUCTS_ACCOUNTS_RENTAL]
          // },
          {
            title: 'Game items',
            href: `/products/${ProductTypeEnum.GAME_ITEM.toLowerCase()}`,
            permissions: [PermissionsEnum.MANAGE_PRODUCTS_GAME_ITEMS]
          },
          // {
          //   title: 'Memb cards',
          //   href: `/products/${ProductTypeEnum.SERIALS.toLowerCase()}`,
          //   permissions: [PermissionsEnum.MANAGE_PRODUCTS_SERIALS]
          // },
          {
            title: 'Custom payments',
            href: `/products/${ProductTypeEnum.CUSTOM_PAYMENT.toLowerCase()}`,
            permissions: [PermissionsEnum.MANAGE_PRODUCTS_CUSTOM_PAYMENTS]
          }
        ]
      }
    ]
  },
  {
    subheader: 'Sales',
    permissionsOneOf: [
      PermissionsEnum.MANAGE_ORDERS_GOLD,
      PermissionsEnum.MANAGE_ORDERS_POINTS,
      PermissionsEnum.MANAGE_ORDERS_PRIZES,
      PermissionsEnum.MANAGE_ORDERS_ACCOUNTS,
      PermissionsEnum.MANAGE_ORDERS_ACCOUNTS_RENTAL,
      PermissionsEnum.MANAGE_ORDERS_GAME_ITEMS,
      PermissionsEnum.MANAGE_ORDERS_CUSTOM_PAYMENTS,
      PermissionsEnum.MANAGE_ORDERS_SERIALS
    ],
    items: [
      {
        title: 'Gold',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.GOLD.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_GOLD]
      },
      {
        title: 'Prizes',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.PRIZE.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_PRIZES]
      },
      {
        title: 'Accounts',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.ACCOUNT.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_ACCOUNTS]
      },
      {
        title: 'Loyalty points',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.LOYALTY_POINTS.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_POINTS]
      },
      {
        title: 'Custom payments',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.CUSTOM_PAYMENT.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_CUSTOM_PAYMENTS]
      },
      {
        title: 'Game items',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.GAME_ITEM.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_GAME_ITEMS]
      },
      {
        title: 'Memb cards',
        icon: ShoppingBasketIcon,
        href: `/management/orders/${ProductTypeEnum.SERIALS.toLowerCase()}`,
        permissions: [PermissionsEnum.MANAGE_ORDERS_SERIALS]
      }
    ]
  },
  {
    subheader: 'Analytics',
    permissionsOneOf: [PermissionsEnum.MANAGE_ORDERS_ANALYTICS],
    items: [
      {
        title: 'Analytics',
        href: '/management/orders/analytics',
        icon: LibraryBooksIcon,
        permissions: [PermissionsEnum.MANAGE_ORDERS_ANALYTICS]
      }
    ]
  },
  {
    subheader: 'Content',
    permissionsOneOf: [
      PermissionsEnum.MANAGE_BLOG,
      PermissionsEnum.MANAGE_PAGES
    ],
    items: [
      {
        title: 'Pages',
        href: '/management/pages',
        icon: LibraryBooksIcon,
        permissions: [PermissionsEnum.MANAGE_PAGES]
      },
      {
        title: 'Blog',
        href: '/management/blog',
        icon: MenuBookIcon,
        permissions: [PermissionsEnum.MANAGE_BLOG]
      },
      {
        title: 'Feedback',
        href: '/management/reviews',
        icon: FeedbackIcon,
        permissions: [PermissionsEnum.MANAGE_USERS]
      }
    ]
  },
  {
    subheader: 'Users',
    permissionsOneOf: [
      PermissionsEnum.MANAGE_USERS,
      PermissionsEnum.MANAGE_STAFF
    ],
    items: [
      {
        title: 'Customers',
        href: '/management/customers',
        icon: PeopleIcon,
        permissions: [PermissionsEnum.MANAGE_USERS]
      },
      {
        title: 'Staff',
        href: '/management/staff',
        icon: PeopleOutlineIcon,
        permissions: [PermissionsEnum.MANAGE_STAFF]
      },
      {
        title: 'Groups',
        href: '/management/groups',
        icon: SupervisedUserCircleIcon,
        permissions: [PermissionsEnum.MANAGE_STAFF]
      }
    ]
  },
  {
    subheader: 'Payment methods',
    permissions: [PermissionsEnum.MANAGE_PAYMENTS],
    items: [
      {
        title: 'Payment gateways',
        href: '/payment-gateways',
        permissions: [PermissionsEnum.MANAGE_PAYMENTS],
        icon: AccountBalanceIcon
      },
      {
        title: 'Methods by countries',
        href: '/payment-methods/geolocation',
        permissions: [PermissionsEnum.MANAGE_PAYMENTS],
        icon: LanguageIcon
      }
    ]
  },
  {
    subheader: 'Settings',
    permissionsOneOf: [
      PermissionsEnum.MANAGE_SETTINGS,
      PermissionsEnum.MANAGE_LOYALTY_PROGRAM,
      PermissionsEnum.MANAGE_MENUS
    ],
    items: [
      {
        title: 'Loyalty program',
        href: '/loyalty-program',
        permissions: [PermissionsEnum.MANAGE_LOYALTY_PROGRAM],
        icon: LocalOfferIcon
      },
      {
        title: 'Game items',
        href: '/game-items',
        permissions: [PermissionsEnum.MANAGE_GAME_ITEMS],
        icon: GroupWorkIcon
      },
      {
        title: 'Navigation',
        href: '/menus',
        permissions: [PermissionsEnum.MANAGE_MENUS],
        icon: ViewConfigIcon
      },
      {
        title: 'Site settings',
        href: '/settings',
        permissions: [PermissionsEnum.MANAGE_SETTINGS],
        icon: SettingsIcon
      }
    ]
  }
];
