/* eslint-disable react/no-danger */
import React from 'react';
import { Helmet } from 'react-helmet';

const { PUBLIC_URL } = process.env;

function Config() {
  return (
    <Helmet>
      <script src={`${PUBLIC_URL}/config.js`}/>
    </Helmet>
  );
}

export default Config;
