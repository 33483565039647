import { Base64 } from 'js-base64'
import * as moment from 'moment'
import queryString from 'query-string'
import { ProductTypeEnum } from './enums'

export const slugify = text =>
  text
    .toString()
    .toLowerCase()
    .trim()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(/&/g, '-and-') // Replace & with 'and'
    // eslint-disable-next-line no-useless-escape
    .replace(/[^\w\-]+/g, '') // Remove all non-word chars
    // eslint-disable-next-line no-useless-escape
    .replace(/\-\-+/g, '-') // Replace multiple - with single -

export const getDBIdFromGraphqlId = (graphqlId, schema) => {
  // This is temporary solution, we will use slugs in the future
  const rawId = Base64.decode(graphqlId)
  const regexp = /(\w+):(\d+)/
  const [, expectedSchema, id] = regexp.exec(rawId)
  if (schema && schema !== expectedSchema) {
    throw new Error('Schema is not correct')
  }
  return parseInt(id, 10)
}

export const getGraphqlIdFromDBId = (id, schema) =>
  // This is temporary solution, we will use slugs in the future
  Base64.encode(`${schema}:${id}`)

export const priceToString = (price, locale) => {
  const { amount } = price
  if (locale) {
    return amount.toLocaleString(locale, {
      style: 'currency',
      currency: price.currency
    })
  }
  return `${price.currency} ${amount.toFixed(2)}`
}

export const maybe = (exp, d) => {
  try {
    const result = exp()
    return result === undefined || result === null ? d : result
  } catch {
    return d
  }
}

export const removeEmptySpaces = text => text.replace(/\s+/g, '')

export const find = (arr, key, value, isEdge = false, d = []) => {
  let result
  if (isEdge) {
    const res = arr.find(item => item.node[key] === value)
    result = maybe(() => res.node, null)
  } else {
    result = arr.find(item => item[key] === value)
  }
  console.log(result)
  console.log(!!result)
  return result || d
}

export const generateUrl = () => ''

export const resolve = (path, obj) =>
  path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj)

export const parseJsonString = (obj, key) => {
  console.log(obj[key] instanceof String)
  if (!!obj && (typeof obj[key] === 'string' || obj[key] instanceof String)) {
    obj[key] = JSON.parse(obj[key])
  }
  return obj
}

export const tryParseJsonString = jsonString => {
  try {
    return JSON.parse(jsonString)
  } catch (e) {
    return {}
  }
}

export const enumToString = text => {
  if (text === ProductTypeEnum.SERIALS) {
    return 'Memb Cards'
  }
  return text
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, c => c.toUpperCase())
}

export const prettifyString = text =>
  text.replace(/^\w/, c => c.toUpperCase()).replace(/[A-Z]/g, c => ` ${c}`)

export const getScrollPercent = () => {
  const h = document.documentElement
  const b = document.body
  const st = 'scrollTop'
  const sh = 'scrollHeight'
  return ((h[st] || b[st]) / ((h[sh] || b[sh]) - h.clientHeight)) * 100
}

const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`

const formatInt = int => {
  if (int < 10) {
    return `0${int}`
  }
  return `${int}`
}

export const formatDuration = time => {
  const seconds = moment.duration(time).seconds()
  const minutes = moment.duration(time).minutes()
  const hours = moment.duration(time).hours()
  const days = moment.duration(time).days()
  if (days > 0) {
    return `${maybePluralize(days, 'day')} ${formatInt(hours)}:${formatInt(
      minutes
    )}:${formatInt(seconds)}`
  }
  if (hours > 0) {
    return `${formatInt(hours)}:${formatInt(minutes)}:${formatInt(seconds)}`
  }
  if (minutes > 0) {
    return `00:${formatInt(minutes)}:${formatInt(seconds)}`
  }
  return `00:00:${formatInt(seconds)}`
}

// export const cleanUpTypenames = (values) => {
//   const items = [];
//   if (typeof values === 'object') {
//     Object.values(values).map(v => {
//       const { __typename, ...val } = cleanUpTypenames(v);
//       return { ...val };
//     });
//   } else if (Array.isArray(values)) {
//     values.map(value => {
//       items.push(cleanUpTypenames(value));
//     });
//     return items;
//   }
//   return values;
// };

export const objToTag = (key, value) => {
  if (value === undefined || value === null) return value
  if (Array.isArray(value)) {
    let s = ''
    value.map(v => {
      s += `${v}; `
      return null
    })
    return `${prettifyString(key)}: ${s}`
  }
  if (typeof value === 'object') {
    let _str = `${prettifyString(key)}: `
    if (value.gte) {
      _str += `>${value.gte}`
    }
    if (value.lte) {
      if (_str.length > 0) {
        _str += '; '
      }
      _str += `<${value.lte}`
    }
    return _str
  }
  return `${prettifyString(key)}: ${value}`
}

export const objToTags = obj => {
  const tags = []
  // eslint-disable-next-line
  if (obj === undefined || obj === null) return tags
  Object.entries(obj).map(([key, value]) => {
    if (typeof value === 'object') {
      const _tags = objToTags(value)
      const _tagsToStr = _tags.join('; ')
      tags.push(`${prettifyString(key)}: ${_tagsToStr}`)
    } else if (['gte', 'lte'].includes(key)) {
      tags.push(`${key === 'gte' ? '>' : '<'}$${value}`)
    } else {
      tags.push(`${prettifyString(key)}: ${value}`)
    }
    return null
  })
  return tags
}

export const convertEmptyValues = (values, fields, defaultValue = null) => {
  fields.map(field => {
    if (values[field] === '') {
      if (defaultValue === 'undefined') {
        // eslint-disable-next-line no-param-reassign
        values[field] = undefined
      } else {
        // eslint-disable-next-line no-param-reassign
        values[field] = defaultValue
      }
    }
    return null
  })
  return values
}

// export const convertNodeIdToName = (edges, value) => {
//   const product = payoutProducts.find(g => values.product === g.id);
//   valuesToTags.product = product.name;
// };

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export function countryToFlag(isoCode) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
      .toUpperCase()
      .replace(/./g, char =>
        String.fromCodePoint(char.charCodeAt(0) + 127397)
      )
    : isoCode
}

export const handlePaginationVars = ({
  startCursor,
  endCursor,
  newPage,
  currentPage,
  rowsPerPage,
  initialCursor,
  queryVars,
  history,
  silent = false
}) => {
  let pagiVars = {}
  if (newPage === 0) {
    pagiVars = {
      after: initialCursor,
      first: rowsPerPage,
      before: undefined,
      last: undefined
    }
  } else if (newPage > currentPage) {
    pagiVars = {
      after: endCursor,
      first: rowsPerPage,
      before: undefined,
      last: undefined
    }
  } else if (newPage < currentPage) {
    pagiVars = {
      before: startCursor,
      last: rowsPerPage,
      after: undefined,
      first: undefined
    }
  }
  const variables = { ...queryVars, ...pagiVars }
  // handle pagination via URLs to save state
  if (history && !silent) {
    pagiVars.page = newPage
    pagiVars.search = queryVars.filter.search
    history.push({
      search: queryString.stringify(pagiVars)
    })
  }
  return variables
}

export const createIpv6Elem = ipv6 => {
  if (!ipv6) return null
  const pattern = /^([\w\d]*):([\w\d]*):([\w\d]*):([\w\d]*)/
  const res = ipv6.match(pattern)
  if (!res) return null
  console.log('RES', res)
  return `<span title=${ipv6}>${res[0]}...</span>`
}

export const getValueFromObjByKey = (obj, key) => {
  const keys = key.split('.')
  let value = obj
  keys.map(k => {
    value = maybe(() => value[k], '')
    return null
  })
  return value
}

export function shallowEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) {
    return false
  }

  for (const key of keys1) {
    if (object1[key] !== object2[key]) {
      return false
    }
  }

  return true
}

export function deepEqual(object1, object2) {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)
  if (keys1.length !== keys2.length) {
    return false
  }
  for (const key of keys1) {
    const val1 = object1[key]
    const val2 = object2[key]
    const areObjects = isObject(val1) && isObject(val2)
    if (
      (areObjects && !deepEqual(val1, val2)) ||
      (!areObjects && val1 !== val2)
    ) {
      return false
    }
  }
  return true
}
export function isObject(object) {
  return object != null && typeof object === 'object'
}
export const copyStringToClipboard = str => {
  // Create new element
  const el = document.createElement('textarea')
  // Set value (string to be copied)
  el.value = str
  // Set non-editable to avoid focus and move outside of view
  el.setAttribute('readonly', '')
  el.style = { position: 'absolute', left: '-9999px' }
  document.body.appendChild(el)
  // Select text inside element
  el.select()
  // Copy text to clipboard
  const success = document.execCommand('copy')
  // Remove temporary element
  document.body.removeChild(el)
  return success
}

export const transitionDuration = {
  enter: 200,
  exit: 200
}
