import gql from 'graphql-tag';

export const ShiftFragment = gql`
  fragment ShiftFragment on Shift {
    id
    created
    totalSales {
      localized
    }
    totalSent {
      localized
    }
    goldOrdersCount
    prizeOrdersCount
    itemsOrdersCount
  }
`;

export const ManagerActiveShiftQuery = gql`
  ${ShiftFragment}
  query ManagerActiveShift {
    shift(activeShift: true) {
      ...ShiftFragment
    }
  }
`;
