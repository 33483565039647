/* eslint-disable import/prefer-default-export */
export const THEMES = {
  LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  UNICORN: 'UNICORN'
};

export const DATE_FORMAT = 'DD/MM/YYYY | HH:mm';
export const DATETIME_FORMAT = 'MMM DD, YYYY, HH:mm';
export const DATETIME_UTC_FORMAT = 'MMM DD, YYYY, HH:mm:ss (UTC Z)';
